html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }

* {
  box-sizing: border-box; }

body {
  position: relative;
  overflow-x: hidden; }

.temp {
  display: none; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.spin {
  animation-name: spin;
  animation-duration: 100000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }
